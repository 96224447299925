<template>
	<S2SCard title="Account Create">
		<v-select :items="accountTypes" v-model="selectedType" item-text="label" return-object label="Select Account Type"></v-select>

		<template v-if="selectedType">
			<v-flex xs12>
				<v-text-field label="Account Name" v-model="accountName"></v-text-field>
			</v-flex>
			<v-flex xs12 v-for="attribute in selectedType.attributes" :key="attribute.key">
				<v-text-field
					v-if="attribute.fieldType === 'text' || attribute.fieldType === 'numeric'"
					:name="attribute.key"
					:required="attribute.required"
					:label="attribute.label"
					v-model="model[attribute.key]"
				></v-text-field>
				<v-text-field
					v-if="attribute.fieldType === 'password'"
					type="password"
					:name="attribute.key"
					:required="attribute.required"
					:label="attribute.label"
					v-model="model[attribute.key]"
				></v-text-field>
				<v-select
					v-else-if="attribute.fieldType === 'option'"
					:items="sanitizeLookup(attribute.validation)"
					:label="attribute.label"
					v-model="model[attribute.key]"
				></v-select>
			</v-flex>
			<v-flex xs12 class="text-xs-right">
				<v-btn color="accent" @click="onAccountAdd()">Save</v-btn>
			</v-flex>
		</template>
	</S2SCard>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "Accounts",

	computed: {
		accountTypes: function() {
			return this.$store.state.organisations.accounts.accountTypes;
		},
		status: function() {
			return this.$store.state.organisations.accounts.status;
		}
	},

	data: function() {
		return {
			selectedType: null,
			model: {},
			accountName: ""
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/accounts/fetchAccountTypes");
	},

	methods: {
		async onAccountAdd() {
			const apiObj = {};
			const properties = [];
			for (let item in this.model) {
				properties.push({ key: item, value: this.model[item] });
			}

			apiObj.methodKey = this.selectedType.key;
			apiObj.name = this.accountName;
			apiObj.properties = properties;

			const success = await this.$store.dispatch("organisations/accounts/addAccount", apiObj);
			if (success) this.$router.push({ name: "organisation-accounts" });
		},
		sanitizeLookup(lookupData) {
			const lookupJson = JSON.parse(lookupData);

			const lookups = [];
			for (let lookup in lookupJson) {
				lookups.push(lookupJson[lookup]);
			}
			return lookups;
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
